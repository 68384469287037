import React from 'react';
import cn from 'classnames';
import styles from './DialogContent.module.scss';
import MuiDialogContent from '@mui/material/DialogContent';
import { UiDialogContentProps } from './DialogContent.interface';

const DialogContent: React.FC<UiDialogContentProps> = ({ children, className, variant, ...props }) => (
  <MuiDialogContent
    {...props}
    className={cn(styles.accountDialogContent, className, { [styles.accountDialogContentClean]: variant === 'clean' })}
  >
    {children}
  </MuiDialogContent>
);

export default DialogContent;
