import React, { PropsWithChildren } from 'react';
import cn from 'classnames';
import styles from './DialogHeader.module.scss';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { DialogHeaderProps } from './DialogHeader.interface';

const DialogHeader: React.FC<PropsWithChildren<DialogHeaderProps>> = ({
  children,
  className,
  onClose,
  variant,
  ...props
}) => (
  <div
    {...props}
    className={cn(styles.accountDialogHeader, { [styles.accountDialogHeaderClean]: variant === 'clean' }, className)}
  >
    <IconButton
      className={cn(styles.accountDialogHeaderClose, { [styles.accountDialogHeaderCloseClean]: variant === 'clean' })}
      onClick={onClose}
    >
      <CloseIcon className='icon' />
    </IconButton>
    <div className={cn(styles.accountDialogTitle, { [styles.accountDialogTitleClean]: variant === 'clean' })}>
      {children}
    </div>
  </div>
);

export default DialogHeader;
