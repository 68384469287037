import useSWR, { KeyedMutator } from 'swr';
import { AxiosResponse } from 'axios';
import { initEnrollPreviewData, initUserEnroll } from 'context/initialState';
import { UseCheckoutDetails } from 'context/checkoutDetails.context';
import { useEffect, useState } from 'react';
import checkoutApi from 'services/api/checkout';
import $account from 'services/Account';
import { useMemberApi } from 'hooks/useMemberApi';
import {
  AddressInput,
  EnrollInput,
  MemberInfoOutput,
  PurchaseBriefOverviewOutput,
  PurchaseBriefPlanOutput,
  PurchaseBriefProductOutput,
  PurchaseCardInfoOutput,
  PurchaseBriefPromotionOutput,
  PurchaseBriefPromotionOutputBanner,
  PurchaseBriefUserOutput,
  PurchaseBriefLineItemsInfoOutput,
  EnrollPreviewOutput,
  PurchaseHouseholdMemberNewInput,
  PurchaseHouseholdMemberExistingInput,
  UserOutput,
  AddressOutput,
  PurchaseBriefHouseholdMemberOutputExpires,
  EnrollPreview200Response,
} from 'services/api/client';

export type AddressInterface = AddressInput | Omit<AddressOutput, 'country'>;
export type HouseholdMember = PurchaseHouseholdMemberNewInput | PurchaseHouseholdMemberExistingInput;
export type HouseholdMemberInfo = PurchaseHouseholdMemberNewInput & {
  id: string;
  new?: boolean;
  active?: boolean;
  expires?: null | PurchaseBriefHouseholdMemberOutputExpires;
};
export interface UserInterface extends PurchaseBriefUserOutput {
  household_members: Array<HouseholdMemberInfo>;
  travel_protection?: PurchaseBriefProductOutput | null;
  card?: PurchaseCardInfoOutput | null;
}
export interface PlanInterface extends PurchaseBriefPlanOutput {
  selected?: boolean;
  parentPlan?: string;
  one_time_purchase_product?: boolean;
}
export interface PromotionInterface extends PurchaseBriefPromotionOutput {
  title: string;
  banner: null | PurchaseBriefPromotionOutputBanner;
}
export type UserEnrollInterface = PurchaseBriefOverviewOutput;
export interface SegmentMessageInterface {
  event: string;
  userId: string;
  properties: {
    source: string;
    context: {
      app: {
        build: string;
        name: string;
        namespace: string;
        version: string;
      };
    };
    [key: string]: unknown;
  };
}
export type UserDataSubscription = {
  extra?: {
    invoice_id: string;
    order_completed_message: undefined | SegmentMessageInterface;
  };
  subscription?: {
    invoice_id: string;
    order_completed_message: undefined | SegmentMessageInterface;
    plan_type: 'trial' | 'year' | 'month';
  };
};
export type UserDataInterface = UserDataSubscription & {
  user: UserOutput & { branch: string };
};
export interface PreviewDataInterface {
  enrollPreview: EnrollPreviewOutput;
  error: AxiosResponse<HttpResponseError> | undefined;
  isLoading: boolean;
}

const useSWRoptions = {
  shouldRetryOnError: false,
  revalidateOnFocus: false,
  errorRetryCount: 2,
  errorRetryInterval: 500,
};

const overview = async (isUpgrade: boolean) => {
  return isUpgrade ? await checkoutApi.memberSubscriptionUpgradeOverview() : await checkoutApi.enrollInfo();
};

const UseUserEnrollInfo = (): {
  enrollInfo: UserEnrollInterface;
  errorType: string | undefined;
  isVRMember: boolean;
  isValidating: boolean;
} => {
  const isUpgrade = $account.roleVr();
  const { data, error, isValidating } = useSWR('enroll-info', () => overview(isUpgrade), useSWRoptions);
  const isVRMember = !!(error && error.status === 403);
  const enrollInfo = (data ? data : initUserEnroll) as UserEnrollInterface;
  const { config, promotion, plans } = JSON.parse(JSON.stringify(enrollInfo)) as UserEnrollInterface;
  const { plan_extension_preselect, plan_extension_preselect_product } = config;
  if (promotion && plan_extension_preselect && plan_extension_preselect_product) {
    plans.forEach((plan: PlanInterface) => {
      const extension = plan.subscription_extension.find(
        (extension) => extension.id === plan_extension_preselect_product
      );
      if (extension) {
        plan.parentPlan = plan.id;
        plan.id = plan_extension_preselect_product;
        plan.one_time_purchase_product = true;
        plan.title = promotion.badge || extension.title;
        plan.period.number = extension.period.number;
        plan.price.cost *= extension.period.number;
        plan.price.amount = extension.price.amount;
        plan.price.discount = plan.price.cost - plan.price.amount;
      }
    });
  }
  return {
    enrollInfo: { ...enrollInfo, plans },
    errorType: error?.data?.error?.type,
    isVRMember,
    isValidating,
  };
};

const getAddonPayload = (
  info: MemberInfoOutput,
  user: UserInterface,
  payload: EnrollInput,
  selectedPlan: PlanInterface | null
) => {
  const addonUpgrade = !!info && !!selectedPlan && 'year' === info.plan_type;
  if (!addonUpgrade) {
    return null;
  }
  const { ...addonPayload } = payload;
  if (selectedPlan && addonUpgrade) {
    const travel_protection = !!addonPayload.travel_protection;
    const household_members = addonPayload.household_members ?? [];
    if (info.travel_protection && travel_protection) {
      addonPayload.travel_protection = undefined;
    }
    if (!household_members.length) {
      addonPayload.household_members = undefined;
    }
  }
  return addonPayload;
};

const useEnrollPreviewCallback = (): ((payload: EnrollInput) => Promise<EnrollPreviewOutput>) => {
  const {
    state: { selectedPlan, user },
  } = UseCheckoutDetails();
  const { data: info } = useMemberApi<MemberInfoOutput>('memberInfo');
  return (payload: EnrollInput) => {
    const addonPayload = getAddonPayload(info!, user, payload, selectedPlan);
    return ($account.roleVr()
      ? addonPayload
        ? checkoutApi.memberSubscriptionAddonPreview(addonPayload)
        : checkoutApi.memberSubscriptionUpgradePreview(payload)
      : checkoutApi.enrollPreview(payload)) as unknown as Promise<EnrollPreviewOutput>;
  };
};

const UseUserEnrollPreview = (
  payload: EnrollInput
): {
  mutate: KeyedMutator<AxiosResponse<EnrollPreview200Response>>;
  triggerEnrollPreview: (payloadData: EnrollInput) => Promise<EnrollPreviewOutput | undefined>;
} => {
  const isUpgrade = $account.roleVr();
  const [errorState, setErrorState] = useState<AxiosResponse<HttpResponseError> | undefined>(undefined);
  const {
    dispatch,
    state: { selectedPlan, user },
  } = UseCheckoutDetails();
  const { data: info } = useMemberApi<MemberInfoOutput>('memberInfo');
  const enrollPreview = (payload: EnrollInput) => () => {
    const addonPayload = getAddonPayload(info!, user, payload, selectedPlan);
    return isUpgrade
      ? addonPayload
        ? checkoutApi.memberSubscriptionAddonPreview(addonPayload)
        : checkoutApi.memberSubscriptionUpgradePreview(payload)
      : checkoutApi.enrollPreview(payload);
  };
  const { data, error, isValidating, mutate } = useSWR('enroll-preview', enrollPreview(payload), useSWRoptions);
  useEffect(() => {
    const enrollPreview = (data ? data : initEnrollPreviewData) as EnrollPreviewOutput;
    setErrorState(error);
    const { primary, household_members } = JSON.parse(
      JSON.stringify(enrollPreview.lines)
    ) as PurchaseBriefLineItemsInfoOutput;
    dispatch({
      type: 'SET_PREVIEW_DETAILS',
      payload: {
        error: errorState,
        enrollPreview: {
          ...enrollPreview,
          lines: {
            ...enrollPreview.lines,
            primary,
            household_members,
          },
        },
        isLoading: isValidating,
      },
    });
  }, [data, dispatch, errorState, error, setErrorState, isValidating]);

  const triggerEnrollPreview = async (payloadData: EnrollInput): Promise<EnrollPreviewOutput | undefined> => {
    try {
      const postData = await enrollPreview(payloadData)();
      mutate(postData as unknown as AxiosResponse<EnrollPreview200Response>, false);
      return postData as unknown as EnrollPreviewOutput;
    } catch (e) {
      setErrorState(e as AxiosResponse<HttpResponseError>);
    }
  };

  return {
    mutate,
    triggerEnrollPreview,
  };
};

const postUserEnroll = async <Data>(
  payload: EnrollInput & { mrp?: string },
  info: MemberInfoOutput,
  user: UserInterface,
  selectedPlan: PlanInterface
): Promise<Data> => {
  const isUpgrade = $account.roleVr();
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.get('mrp')) {
    payload.mrp = urlParams.get('mrp')!;
  }
  const enrollRun = (payload: EnrollInput) => () => {
    const addonPayload = getAddonPayload(info, user, payload, selectedPlan);
    return isUpgrade
      ? addonPayload
        ? checkoutApi.memberSubscriptionAddon(addonPayload)
        : checkoutApi.memberSubscriptionUpgrade(payload)
      : checkoutApi.enrollRun(payload);
  };
  const data = await enrollRun(payload)();
  return data as unknown as Data;
};

export { UseUserEnrollInfo, UseUserEnrollPreview, postUserEnroll, useEnrollPreviewCallback };
