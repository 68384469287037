import React from 'react';
import cn from 'classnames';
import styles from './DialogActions.module.scss';
import MuiDialogActions from '@mui/material/DialogActions';
import { DialogActionsProps } from './DialogActions.interface';

const DialogActions: React.FC<DialogActionsProps> = ({
  children,
  className,
  actionsRow,
  actionsAlign,
  spacing,
  variant = 'default',
  ...props
}) => (
  <MuiDialogActions
    {...props}
    className={cn(styles.accountDialogActions, className, 'dialog_actions', {
      [styles.accountDialogActionsRow]: !!actionsRow,
      [styles.actionsAlignRight]: actionsAlign === 'right',
      [styles.actionsAlignRight]: actionsAlign === 'right',
      [styles.actionsSpacingTopBottom]: spacing === 'top-bottom',
      [styles.accountDialogEditHHMActions]: variant === 'editHHM',
    })}
    disableSpacing
  >
    {children}
  </MuiDialogActions>
);

export default DialogActions;
