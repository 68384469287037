import React, { PropsWithChildren } from 'react';
import Dialog from '@mui/material/Dialog';
import styles from './AccountDialog.module.scss';
import { AccountDialogProps } from './AccountDialog.interface';
import { useDialog } from 'hooks/useDialog';
import DialogActions from '../DialogActions/DialogActions';
import DialogContent from '../DialogContent/DialogContent';
import DialogHeader from '../DialogHeader/DialogHeader';
import cn from 'classnames';

const AccountDialog: React.FC<PropsWithChildren<AccountDialogProps>> = ({
  name,
  title,
  subTitle,
  children,
  actions,
  actionsRow,
  actionsAlign,
  open,
  onClose,
  variant = 'default',
}) => {
  const { isOpen, close } = useDialog(name);
  return (
    <Dialog
      scroll='body'
      open={open ?? isOpen}
      onClose={onClose ?? close}
      fullWidth
      maxWidth={false}
      classes={{
        paper: cn({ [styles.accountDialog]: variant === 'default', [styles.accountDialogClean]: variant === 'clean' }),
      }}
      aria-labelledby='customized-dialog-title'
    >
      <DialogHeader id='customized-dialog-title' onClose={onClose ?? close} variant={variant}>
        {title}
      </DialogHeader>
      <DialogContent variant={variant}>
        {subTitle && (
          <div
            className={cn({
              [styles.accountDialogContentSubTitle]: variant !== 'clean',
              [styles.accountDialogContentSubTitleClean]: variant === 'clean',
            })}
          >
            {subTitle}
          </div>
        )}
        {children}
      </DialogContent>
      {actions && (
        <DialogActions actionsAlign={actionsAlign} actionsRow={actionsRow}>
          {actions}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default AccountDialog;
